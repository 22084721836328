import axios from '@/utils/http'

// 获取banner
export const getBanner = function (data) {
  return axios.get('/power/cms/banner/listPage', { params: data })
}
// 人才推荐
export const recommend = function (data) {
  return axios.get('/power/cms/educational/recommend/listPage', { params: data })
}
// 修改密码
export const changePwd = function (data) {
  return axios.put('/power/cms/login/edit', data)
}
// 招聘者条件
export const codition = function (data) {
  return axios.get('/power/cms/educational/codition', { params: data })
}
// 招聘者搜索
export const search = function (data) {
  return axios.get('/power/cms/educational/condition/listPage', { params: data })
}
// 求职详情
export const jobDetail = function (id) {
  return axios.get('/power/cms/educational/condition/' + id)
}
// 求职详情2
export const jobDetail2 = function (id) {
  return axios.get('/power/cms/educational/banner/condition/' + id)
}
// 求职详情
export const customer = function (id) {
  return axios.get('/power/cms/educational/customer/condition/' + id)
}

// 个人中心
// 简历收藏
export const collection = function (data) {
  return axios.get('/power/cms/userCollection/resumeList', { params: data })
}
// 我发布的
export const pRecruit = function (data) {
  return axios.get('/power/cms/pRecruit/listPage', { params: data })
}
// 发布招聘
export const pRecruitAdd = function (data) {
  return axios.post('/power/cms/pRecruit/add', data)
}
// 获取职位详情
export const getpRecruit = function (id) {
  return axios.get('/power/cms/pRecruit/detail/' + id)
}

// 更新职位详情
export const updatepRecruit = function (data) {
  return axios.put('/power/cms/pRecruit/edit', data)
}
// 删除职位
export const removeRecruit = function (data) {
  return axios.delete('/power/cms/pRecruit/remove', { data })
}
// 个人中心数量
export const count = function () {
  return axios.get('/power/cms/recruiterStatistics/count')
}
// 是否填写企业基本信息
export const validate = function () {
  return axios.put('/power/cms/enterprise/login/validate')
}
// 填写企业基本信息
export const enterpriseAdd = function (data) {
  return axios.post('/power/cms/enterprise/add', data)
}
// 编辑企业基本信息
export const enterpriseEdit = function (data) {
  return axios.put('/power/cms/enterprise/edit', data)
}
// 获取企业基本信息
export const getenterprise = function () {
  return axios.get('/power/cms/enterprise/detail/')
}
// 获取下拉字典
export const getDictType = function (dictType) {
  return axios.get('/power/dict/data/type/' + dictType)
}
// 获取城市
export const cityCode = function (data) {
  return axios.get('/power/cms/city/listPage', { params: data })
}

// 获取基本信息
export const getBaseInfo = function (data) {
  return axios.post('/power/getInfo/pc', data)
}
// 获取招聘基本信息
export const getRecruitInfo = function (data) {
  return axios.get('/power/cms/enterprise/detail', { params: data })
}
// 岗位投递记录
export const resumeList = function (data) {
  return axios.get('/power/cms/pDelivery/push/listPage', { params: data })
}
// 岗位查看
export const queryGwck = function (data) {
  return axios.get('/power/cms/pDelivery/view/listPage', { params: data })
}
// 刷新职位
export const refush = function (id) {
  return axios.get('/power/cms/pRecruit/refush/' + id)
}
// 支付
export const pay = function (data) {
  return axios.post('/power/cms/order/add', data)
}
// 支付明细
export const wallet = function (data) {
  return axios.get('/power/cms/order/listPage', { params: data })
}
// 支付价格
export const payDetail = function (data) {
  return axios.get('/power/cms/pRefush/detail', { params: data })
}
// 支付详情
export const orderDetail = function (id) {
  return axios.get('/power/cms/order/detail/' + id)
}
// 剩余刷新次数
export const getSysxcs = function () {
  return axios.get('/power/cms/pRecruit/customer/condition')
}
